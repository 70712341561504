import {registerPaymentMethod} from '@woocommerce/blocks-registry';
import {getSettings} from "../util";
import {PaymentMethodLabel, PaymentMethod} from "../../components/checkout";
import {canMakePayment, LocalPaymentIntentContent} from "./local-payment-method";

const getData = getSettings('stripe_wechat_data');

if (getData()) {
    registerPaymentMethod({
        name: getData('name'),
        label: <PaymentMethodLabel
            title={getData('title')}
            paymentMethod={getData('name')}
            icons={getData('icon')}/>,
        ariaLabel: 'WeChat',
        canMakePayment: canMakePayment(getData),
        content: <PaymentMethod content={LocalPaymentIntentContent} getData={getData} shouldCreatePaymentMethod={false}/>,
        edit: <PaymentMethod content={LocalPaymentIntentContent} getData={getData} shouldCreatePaymentMethod={false}/>,
        placeOrderButtonLabel: getData('placeOrderButtonLabel'),
        supports: {
            showSavedCards: false,
            showSaveOption: false,
            features: getData('features')
        }
    })
}
